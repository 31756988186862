import React from "react";

function Varn(props){
const display = props.items.map((i,index) => <div className={(props.active === i.varn)?"col box active":"col box"} key={index} onClick={()=>props.handleClick(i.id,i.varn)}>{i.varn}</div> )
return (
    <div className="row">
        {display}
    </div>
)
}
export default Varn