import React,{useState} from 'react';
import './App.css';
import Varn from './Varn'
import varnMala from './varnmala'
function App() {
  function isChrome(){
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

if (isIOSChrome) {
   return true;
} else if(
  isChromium !== null &&
  typeof isChromium !== "undefined" &&
  vendorName === "Google Inc." &&
  isOpera === false &&
  isIEedge === false
) {
  return true;
} else { 
  return false;
}
  }
  const [suites, setSuites] = useState([])
  const [active, setActive] = useState('')
  function handleClick(name,varn){
    if(isChrome()){
    window.navigator.vibrate(50);
  }
    setActive(varn)
 const findSuites = []
    for(var place =1; place<=8;place++){
    var total_of_name = [(name*2)+place]%8
    if(total_of_name === 0){
      total_of_name = 8
    }
    var total_of_place = [(place*2)+name]%8
    if(total_of_place === 0){
      total_of_place = 8
    }
      if(total_of_place > total_of_name ){
      findSuites.push(place)
      }
    }
    setSuites(findSuites)
  }
  const displayVarnmala = varnMala.map((item,index) => <Varn key={index} active={active} items={item} handleClick={handleClick} /> )

  const displayResult = suites.map((i,index)=> {
    return( <Varn key={index} active={active} items={varnMala[i-1]}  handleClick={handleClick}/>)
  } )
   return (
    <div className="App">
          <div className="header">
            <div className="row">
            <div className="container">
              <div className="col">
            <h1> Find out the names which suites you? </h1>
            </div>
            </div>
            </div>
          </div>
          <div className="body">

          <div className="container">

             {displayVarnmala} 

          
          </div>
    <div className="container">
    {(suites.length> 0) &&<h3 style={{color:'#fff'}}>Varns which suits you! </h3>}
    <div className="results">
      {displayResult}
    </div>
    </div>
    </div>
    </div>
  );
  
}

export default App;
