const firstRow = [
   
    {'varn':'अ','id':1},
    {'varn':'आ','id':1},
    {'varn':'इ','id':1},
    {'varn':'ई','id':1},
    {'varn':'उ','id':1},
    {'varn':'ऊ','id':1},
    {'varn':'ऋ','id':1},
    {'varn':'ए','id':1},
    {'varn':'ऐ','id':1},
    {'varn':'ओ','id':1},
    {'varn':'औ','id':1}
]
const secondRow = [
    {'varn':'क','id':2},
    {'varn':'ख','id':2},
    {'varn':'ग','id':2},
    {'varn':'घ','id':2},
    {'varn':'ङ','id':2}
]    
   
const thirdRow = [
    {'varn':'च','id':3},
    {'varn':'छ','id':3},
    {'varn':'ज','id':3},
    {'varn':'झ','id':3},
    {'varn':'ञ','id':3}
]

const fourthRow = [
    {'varn':'ट','id':4},
    {'varn':'ठ','id':4},
    {'varn':'ड','id':4},
    {'varn':'ढ','id':4},
    {'varn':'ण','id':4}
]
  
const fifthRow = [
    {'varn':'त','id':5},
    {'varn':'थ','id':5},
    {'varn':'द','id':5},
    {'varn':'ध','id':5},
    {'varn':'न','id':5}
]

const sixthRow = [
    {'varn':'प','id':6},
    {'varn':'फ','id':6},
    {'varn':'ब','id':6},
    {'varn':'भ','id':6},
    {'varn':'म','id':6}
]

const seventhRow = [
    {'varn':'य','id':7},
    {'varn':'र','id':7},
    {'varn':'ल','id':7},
    {'varn':'व','id':7},
    {'varn':'श','id':7}
]

const eightRow = [
    {'varn':'ष','id':8},
    {'varn':'स','id':8},
    {'varn':'ह','id':8},
    {'varn':'ड़','id':8},
    {'varn':'ढ़','id':8}
]
const varnMala = [
    firstRow, secondRow, thirdRow, fourthRow,fifthRow,sixthRow,seventhRow,eightRow
]

export default varnMala